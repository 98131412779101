<template>
  <div class="room-info" @click="onClick">
    <!--   :style="data.bg ? { backgroundImage: 'url(' + data.bg.url + ')' } : null"  -->
    <h1 class="info-title" v-html="data.title"></h1>
    <div class="info-image">
      <img v-if="data.image" :src="data.image" />
    </div>
    <simplebar
      class="info-message"
      data-simplebar-auto-hide="false"
      v-html="data.message"
    >
    </simplebar>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
export default {
  name: 'Info',
  components: {
    simplebar
  },
  props: {
    data: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    onClick() {
      console.log('next event is fired');
      this.$emit('next');
    }
  }
};
</script>

<style scoped></style>
